<template>
  <div class="home pb0">
    <CommonHeader title="充值" />
    <div class="withdrawal-title">充值金额<template v-if="merchantInfo">({{ merchantInfo.fee_type_text }})</template></div>
    <div class="input-view flex align-items-c">
      <div><template v-if="merchantInfo">{{ merchantInfo.fee_type == 'CNY' ? '￥' : merchantInfo.fee_type }}</template></div>
      <input
        v-model="price"
        type="number"
        class="flex-one"
        placeholder="请输入充值金额"
      />
    </div>
    <div class="all-bg" style="background: #fff">
      <div class="withdrawal-title">充值方式</div>
      <PayTypeView
        v-model="payType"
        :pay_evidence="pay_evidence"
        @upload_pay_evidence="uploadPayEvidenceAction"
      />
    </div>
    <div
      class="btn"
      :style="rightBtnStyle"
      @click="payConfirmAction"
    >确认{{ payType == 'bank_transfer' ? '提交' : '充值' }}</div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed, ref } from "vue";
import PayTypeView from "@/components/PayType";
import { payAction, returnWxPayType } from "@/utils/util";
import { Toast } from "vant";
import { recharge } from "@/api/user";

export default {
  components: {
    PayTypeView,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const payType = ref("wx");
    const price = ref("");
    const pay_evidence = ref("");

    let payLock = false;

    // 获取button样式集合
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    const payConfirmAction = () => {
      if (!price.value || isNaN(price.value)) {
        Toast("请输入正确的充值金额");
        return false;
      }
      const data = {
        pay_type: payType.value,
        price: price.value,
      };
      if (payType.value == "wx") {
        data.pay_type = returnWxPayType();
      }
      if (payType.value == "bank_transfer") {
        if (!pay_evidence.value) {
          Toast("请上传支付凭证");
          return false;
        }
        data.pay_evidence = pay_evidence.value;
      }

      if (payLock) return false;
      payLock = true;

      recharge(data)
        .then((res) => {
          if (data.pay_type == returnWxPayType()) {
            payAction(
              res.data.data.config,
              () => {
                Toast("支付成功");
                payLock = false;
                router.back();
              },
              () => {
                payLock = false;
              },
              () => {
                payLock = false;
              }
            );
          } else if (data.pay_type == "bank_transfer") {
            Toast("操作成功,审核后到账！");
            router.back();
          }
        })
        .catch((message) => {
          Toast(message);
          payLock = false;
        });
    };

    const uploadPayEvidenceAction = (image) => {
      pay_evidence.value = image;
    };

    return {
      rightBtnStyle,
      colorData,
      price,
      merchantInfo,

      payType,
      pay_evidence,
      payConfirmAction,
      uploadPayEvidenceAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  background: #f5f5f5;
}

.withdrawal-title {
  margin: 64px 32px 32px 32px;
  font-size: 28px;
  color: #000;
  font-weight: 500;
}

.input-view {
  height: 96px;
  border: 2px solid #eee;
  background: #fff;
  border-radius: 8px;
  margin: 0 32px;
  padding: 22px 32px;
  box-sizing: border-box;
  font-size: 36px;
  font-weight: 500;
  color: #000;

  input {
    border: none;
  }
}

.btn {
  height: 72px;
  line-height: 72px;
  text-align: center;
  margin: 32px;
  margin-top: 48px;
}

.all-bg{
  margin-top: 64px;
  padding-bottom: 32px;
  .withdrawal-title{
    padding: 32px 32px 0 32px;
    margin: 0;
  }
}
</style>